/**
 * Parses token and returns the parsed object
 * @param {String} token
 * @returns {Object|null}
 */
export function parseToken(token = '') {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  } catch(e) {
    console.log(e)
    return {}
  }
}