import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import Login from "./pages/Login";
import Forgot from "./pages/Forgot";
import Link from "./pages/Link"
import RestrictedProvider from "./pages/RestrictedProvider"
import NewPassword from "./pages/NewPassword"
import "./App.css";

function App() {
  return (
    <Router>
      <AppRoute />
    </Router>
  );
}

function AppRoute() {
  return (
    <Switch>
      <Route exact path="/">
        <Login />
      </Route>
      <Route exact path="/forgot">
        <Forgot />
      </Route>
      <Route exact path="/link-oid">
          <Link />
      </Route>
        <Route exact path="/restricted-provider">
            <RestrictedProvider />
        </Route>
      <Route exact path="/reset-password">
          <NewPassword />
      </Route>
      <Redirect to="/" />
    </Switch>
  );
}

export default App;
export { AppRoute };
