import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  form: {
    marginTop: 1,
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        border: '1px solid #80B8FA',
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #80B8FA',
      },
    },
  },
  loginBox: {
    width: 450,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  loginBoxLarge: {
    width: 780
  },
  logo: {
    cursor: "pointer",
    width: "224px",
    height: '42px'
  },
  loginCard: {
    background: "#FFFFFF",
    border: "1px solid #D5DFE9",
    boxSizing: "border-box",
    boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    padding: "20px",
  },
  cardHeader: {
    flexDirection: "column",
    justifyContent: "center",
    paddingBottom: 20
  },
  cardContent: {
    padding: "20px !important"
  },
  text: {
    fontFamily: "Roboto",
    letterSpacing: "- 1.30385e-09px",
    margin: 0,
    fontStyle: "normal",
    fontWeight: "normal"
  },
  welcomeText: {
    fontSize: 22,
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    lineHeight: "18px",
    letterSpacing: "-1.30385e-09px",
    color: "#27292B",
  },
  helperText: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "18px",
    letterSpacing: "-1.30385e-09px",
    color: "#9CA8B4",
    marginTop: 6
  },
  subText:{
    fontSize: "14px",
    lineHeight: "22px"
},
  formControl: {
    width: "100%",
    margin: "24px 0 0 0",
    "& label": {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "16px",
      letterSpacing: "-1.30385e-09px",
      color: "#5C6269",
    },
  },
  labelSelect: {
    fontSize: 14
  },
  signupText: {
    cursor: "pointer",
    fontSize: 12,
    display: "inline-block",
    float: "left",
    marginLeft: 14,
    color: "#5C6269",
    fontWeight: 500,
    marginTop: 5
  },
  forgotText: {
    cursor: "pointer",
    fontSize: 12,
    display: "inline-block",
    float: "right",
    marginRight: 14,
    color: "#5C6269",
    fontWeight: 500,
    marginTop: 5
  },
  btnLogin: {
    cursor: "pointer",
    width: "100%",
    fontSize: 18,
    fontFamily: "Dosis",
    fontWeight: 500,
    letterSpacing: "- 1.30385e-09px",
    marginTop: 22,
    border: "none",
    color: "#FFFFFF",
    borderRadius: 4,
    background: "#212121",
    height: 56,
    mixBlendMode: "normal",
    "&:hover": {
      background: "rgba(33,33,33,0.9)",
      boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.25)"
    },
    "&:focus": {
      outline: "none"
    }
  },
  btnLoginVerify: {
    cursor: "pointer",
    width: "100%",
    marginTop: 22,
    color: "#5C6269",
    borderRadius: 4,
    background: "#FDFFFF",
    border: "1px solid #D5DFE9",
    height: 56,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:focus": {
      outline: "none"
    },
    "&:hover": {
      background: "#F2F5F9",
      border: "1px solid #D5DFE9",
      borderRadius: "4px",
    }
  },
  btnLoginOpenId: {
    cursor: "pointer",
    width: "100%",
    marginTop: 22,
    color: "#5C6269",
    borderRadius: 4,
    background: "#FDFFFF",
    border: "1px solid #D5DFE9",
    height: 56,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:focus": {
      outline: "none"
    },
    "&:hover": {
      opacity: ".95",
      borderRadius: "4px",
    }
  },
  openIdLogo: {
    maxWidth: "30px"
  },
  dividerContainer: {
    marginTop: 22,
    position: "relative"
  },
  divider: {
    margin: "0 14px",
    color: "#D5DFE9"
  },
  or: {
    color: "#D5DFE9",
    fontSize: 14,
    margin: 0,
    top: "-8px",
    padding: "0 10px",
    position: "absolute",
    left: "calc(50% - 15px)",
    background: "#FFFFFF"
  },
  backLogin: {
    textAlign: "center"
  },
  backLoginText: {
    cursor: "pointer",
    fontWeight: 500,
    fontSize: 12,
    color: "#5C6269",
    marginTop: 22
  },
  loginVerifyText: {
    display: "inline-block",
    fontWeight: 500,
    fontSize: 14,
    color: "#5C6269",
    marginLeft: 10
  },
  footerText: {
    textAlign: "center",
    color: "#5C6269",
    fontSize: 12,
    fontWeight: 500,
    marginTop: 40,
    textDecoration: "none"
  },
  resetPwHelperText: {
    marginTop: 25,
    marginBottom: 5
  },
  select: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "-1.30385e-09px",
  },
  menuItem: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    letterSpacing: "-1.30385e-09px",
    color: "#27292B",
    padding: "15px 13px",
    "& selected": {
    }
  },
  menuItemSelected: {
    background: "#F2F5F9",
  },
  menulist: {
    padding: "5px"
  },
  wrongEmailText: {
    paddingRight: "10px",
    fontSize: "12px",
    lineHeight: "14px",
    overflow: "hidden",
    paddingTop: "5px",
    color: "#D50000",
    display: "flex",
    alignItems: "center",
    margin: "8px 14px"
  },
  icon: {
    marginRight: 8
  },
  progressbar: {
    color: "#FFFFFF"
  },
  openIdButtonContainer: {
    position: "relative",
    overflow: "hidden"
  },
  mt0: {
    marginTop: 0
  }
})