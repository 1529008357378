import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Box, Card, CardHeader } from "@material-ui/core";
import useStyles from "../components/LoginComponent/styles";
import NoLink from "../components/NoLinkComponent/NoLink";
import Link from "../components/LinkComponent/Link";
import { getEnv } from "../helper";
import * as configFile from "../config.json";
import { linkOpenIdAndLoginApi } from "../api/link-openid";

const config = window.config || configFile.default;

const LinkPage = () => {
    const classes = useStyles();
    const history = useHistory();
    const [isLink, setIsLink] = useState(false);
    const [loading, setLoading] = React.useState(false);
    const [email, setEmail] = React.useState();
    const [pw, setPw] = React.useState();
    const [error, setLoadingErr] = React.useState("");
    const [apiLink, setApiLink] = React.useState(null);
    const [sessionKey, setSessionKey] = useState("");

    const configByEnv = config;

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const session = queryParams.get("session");
        setSessionKey(session);
        setApiLink(configByEnv.api.default);
    }, []);
    const handleChangeEmail = (e) => {
        setEmail(e.target.value);
        setLoadingErr("");
    };

    const handleChangePw = (e) => {
        setPw(e.target.value);
        setLoadingErr("");
    };

    const handleLoginAndLink = async (event) => {
        event.preventDefault();
        if (loading) {
            return;
        }
        setLoading(true);
        try {
            const res = await linkOpenIdAndLoginApi(apiLink, sessionKey, email, pw);
            if (res.status === 200) {
                window.location.href = configByEnv.switchApp.default;
            } else if (res.status === 404) {
                setLoadingErr("Email or Password is incorrect.");
            } else {
                setLoadingErr("Something went wrong with login. Please try again.");
            }
        } catch (e) {
            console.error(e);
            setLoadingErr("Something went wrong with login. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    const gotoVeritone = () => {
        window.location = "https://www.veritone.com/";
    };
    const VeritoneLogo = () => {
        return (
            <img
                alt="logo"
                className={classes.logo}
                src="../images/veritone.svg"
                onClick={gotoVeritone}
            />
        );
    };
    const handleBackToLogin = () => {
        history.push("/");
    };

    const handleGoToLinking = () => {
        setIsLink(true);
    };
    const handleForgotPassword = () => {
        history.push("/forgot", {
            destination: {link: apiLink},
            email: email,
        });
    };

    return (
        <div className="App">
            <Box className={classes.loginBox}>
                <Card className={classes.loginCard}>
                    <CardHeader className={classes.cardHeader} avatar={VeritoneLogo()}/>
                    {isLink ? (
                        <Link
                            apiLink={apiLink}
                            email={email}
                            pw={pw}
                            onChangeEmail={handleChangeEmail}
                            onChangePw={handleChangePw}
                            onLoginAndLink={handleLoginAndLink}
                            classes={classes}
                            onForgotPassword={handleForgotPassword}
                            errorLogin={error}
                            loading={loading}
                        />
                    ) : (
                        <NoLink
                            classes={classes}
                            onBackToLogin={handleBackToLogin}
                            onGoToLinking={handleGoToLinking}
                        />
                    )}
                </Card>
                <p className={`${classes.text} ${classes.footerText}`}>
                    &copy; {new Date().getFullYear()} Veritone, Inc. All Rights Reserved.
                </p>
            </Box>
        </div>
    );
};

export default LinkPage;
