import React from 'react';
import {
  Box,
  CardContent,
  TextField
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import { emailValidate, requiredValidate } from "../../helper";

function Forgot({
  email,
  onChangeEmail,
  onChangeStatus,
  onForgotPw,
  classes,
  onBackToLogin,
  forgetting
}) {
  const [error, setError] = React.useState("");
  function handleForgotPassword(e) {
    if (!requiredValidate(email) || !emailValidate(email)) {
      setError(!requiredValidate(email) ? "An email is required." : !emailValidate(email) ? "This is an invalid email." : "");
      return;
    }
    onForgotPw && onForgotPw(e);
  }
  function handleChangeEmail(e) {
    setError("");
    onChangeEmail && onChangeEmail(e);
  }
  return (
    <CardContent className={classes.cardContent}>
      <p className={`${classes.text} ${classes.welcomeText}`}>Forgot Password?</p>
      <p className={`${classes.text} ${classes.helperText}`}>Enter your registered email to reset your password.</p>
      <Box className={classes.form}>
        <TextField
          className={classes.formControl}
          fullWidth
          required
          variant="outlined"
          label="Email"
          InputLabelProps={{
            shrink: true
          }}
          value={email}
          onChange={handleChangeEmail}
          error={!!error}
          helperText={error}
          InputProps={{
            classes: {
              root: classes.select
            },
          }}
          inputProps={{
            "data-test": "forgot-input"
          }}
          data-test="forgot-field"
        />
        <button
          data-test="forgot-button"
          className={classes.btnLogin}
          onClick={handleForgotPassword}
        >
          {forgetting ? <CircularProgress classes={{
            root: classes.progressbar
          }} /> : "SEND"}
        </button>
      </Box>
      <Box className={classes.backLogin}>
        <p className={`${classes.text} ${classes.backLoginText}`} onClick={onBackToLogin}>Back to Login</p>
      </Box>
    </CardContent>
  );
}

export default Forgot;
