import React from "react";
import {Box, CardContent, FormHelperText, TextField} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import CircularProgress from "@material-ui/core/CircularProgress";

import {emailValidate, requiredValidate} from "../../helper";

const errorDefault = {
    email: "",
    password: "",
    destination: "",
};

function Link({
                  apiLink,
                  email,
                  pw,
                  onChangeEmail,
                  onChangePw,
                  onLoginAndLink,
                  classes,
                  onForgotPassword,
                  loading,
                  errorLogin,
              }) {
    const [error, setError] = React.useState(errorDefault);
  const fedEnvs = ['us-fed-1', 'us-fed-2'];
  const isFed = fedEnvs.find(env => window.location.href.includes(env));
  const hidePasswordReset = isFed;

    function handleChangeEmail(e) {
        setError((error) => ({
            ...error,
            email: "",
        }));
        onChangeEmail(e);
    }

    function handleChangePassword(e) {
        setError((error) => ({
            ...error,
            password: "",
        }));
        onChangePw(e);
    }

    function handleLoginAndLink(e) {
        e.preventDefault();
        if (
            !requiredValidate(email) ||
            !emailValidate(email) ||
            !requiredValidate(pw)
        ) {
            setError({
                email: !requiredValidate(email)
                    ? "An email is required."
                    : !emailValidate(email)
                        ? "This is an invalid email."
                        : "",
                password: !requiredValidate(pw)
                    ? "A password is required."
                    : "",
            });
            return;
        }
        onLoginAndLink && onLoginAndLink(e);
    }

    function handleForgotPassword(e) {
        e.preventDefault();
        if (!apiLink) {
            setError({
                destination: "A destination is required.",
            });
            return;
        }
        onForgotPassword && onForgotPassword();
    }

    return (
        <CardContent className={classes.cardContent}>
            <p className={`${classes.text} ${classes.welcomeText}`}>
                Link Account
            </p>
            <p className={`${classes.text} ${classes.helperText}`}>
                Provide the email and password to your aiware account.
            </p>
            <Box className={classes.form}>
                <TextField
                    required
                    className={classes.formControl}
                    fullWidth
                    autoFocus
                    variant="outlined"
                    label="Email"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={email}
                    onChange={handleChangeEmail}
                    error={!!error.email || !!errorLogin}
                    helperText={error.email}
                    classes={{
                        root: classes.select,
                    }}
                    InputProps={{
                        type: "email",
                        classes: {
                            root: classes.select,
                        },
                    }}
                    inputProps={{
                        "data-test": "email-input",
                    }}
                    data-test="email-field"
                    data-testid="email-field"
                />
                <TextField
                    className={classes.formControl}
                    fullWidth
                    required
                    variant="outlined"
                    label="Password"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        type: "password",
                        classes: {
                            root: classes.select,
                        },
                    }}
                    inputProps={{
                        "data-test": "password-input",
                    }}
                    value={pw}
                    onChange={handleChangePassword}
                    error={!!error.password || !!errorLogin}
                    helperText={error.password}
                    data-test="password-field"
                    data-testid="password-field"
                />
                {errorLogin && (
                    <Box
                        data-test="link-error"
                        className={`${classes.wrongEmailText}`}
                    >
                        <InfoIcon className={classes.icon}/> {errorLogin}
                    </Box>
                )}

                <>
                  {!hidePasswordReset && (
                    <p
                      className={`${classes.text} ${classes.forgotText}`}
                      onClick={handleForgotPassword}
                      data-test="forgot-password"
                    >
                      Forgot Password?
                    </p>
                  )}
                    {error.destination && (
                        <FormHelperText>{error.destination}</FormHelperText>
                    )}
                    <button
                        data-testid="link-button"
                        data-test="link-button"
                        className={classes.btnLogin}
                        onClick={handleLoginAndLink}
                    >
                        {loading ? (
                            <CircularProgress
                                data-test="link-loading"
                                classes={{
                                    root: classes.progressbar,
                                }}
                            />
                        ) : (
                            "LINK ACCOUNT & LOGIN"
                        )}
                    </button>
                </>
            </Box>
        </CardContent>
    );
}

export default Link;
