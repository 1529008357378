import React from "react";
import { useHistory } from "react-router-dom";
import { Box, Card, CardContent, CardHeader } from "@material-ui/core";
import useStyles from "../components/LoginComponent/styles";

const RestrictedProvider = () => {
    const classes = useStyles();
    const history = useHistory();

    const gotoVeritone = () => {
        window.location = "https://www.veritone.com/";
    };
    const VeritoneLogo = () => {
        return (
            <img
                alt="logo"
                className={classes.logo}
                src="../images/veritone.svg"
                onClick={gotoVeritone}
            />
        );
    };
    const handleBackToLogin = () => {
        history.push("/");
    };

    return (
        <div className="App">
            <Box className={classes.loginBox}>
                <Card className={classes.loginCard}>
                    <CardHeader className={classes.cardHeader} avatar={VeritoneLogo()} />
                    <CardContent className={classes.cardContent}>
                        <p
                            className={`${classes.text} ${classes.welcomeText}`}
                            data-test="header"
                        >
                            Authentication Restriction
                        </p>
                        <Box mt={3} />
                        <p
                            className={`${classes.text} ${classes.subText}`}
                            data-testid="sub-header"
                        >
                            Authenticating with this OpenID provider has been restricted by an
                            admin of your account. We are sorry for the inconvenience. Please
                            return to the login screen and choose another authentication
                            provider.
                        </p>
                        <Box mt={5} />
                        <button
                            data-test="go-to-login"
                            className={classes.btnLogin}
                            onClick={handleBackToLogin}
                        >
                            BACK TO LOGIN
                        </button>
                    </CardContent>
                </Card>
                <p className={`${classes.text} ${classes.footerText}`}>
                    &copy; {new Date().getFullYear()} Veritone, Inc. All Rights Reserved.
                </p>
            </Box>
        </div>
    );
};

export default RestrictedProvider;
