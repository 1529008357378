const linkOpenIdRoute = "/api/admin/openid/linkUserToOid";

export const linkOpenIdAndLoginApi = (
    apiUrl,
    sessionKey,
    email,
    password
) => {
    return fetch(apiUrl + linkOpenIdRoute, {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify({
            userName: email,
            password: password,
            sessionKey,
        }),
    });
};
