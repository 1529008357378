import { Box, Card, CardHeader } from "@material-ui/core";
import React, { useEffect } from "react";
import Cookies from 'universal-cookie';
import { useHistory,useLocation } from "react-router-dom";
import * as configFile from '../config.json';
import { destination } from '../constant';
import "../App.css";
import useStyles from "../components/LoginComponent/styles";
import { parseToken } from "../helpers";

const WIDGET_ELEMENT_ID = 'reset-password-widget'

const config =  window.config || configFile.default;
const cfgByEnv = config;

function NewPassword() {
  const classes = useStyles();
  const history = useHistory();
  const queryParams = new URLSearchParams(window.location.search);

  const username = queryParams.get('username')
  const resetToken = queryParams.get('resettoken')
  const organizationInviteId = queryParams.get('organizationinviteid')
  const redirectURI = queryParams.get('redirect')

  const tokenData = resetToken ? parseToken(resetToken) : {}
  const enablePasswordRestriction = tokenData ? tokenData.enablePasswordRestriction : false

  const gotoVeritone = () => {
    window.location = "https://www.veritone.com/";
  };

  const VeritoneLogo = () => {
    return (
      <img
        alt="logo"
        className={classes.logo}
        src="../images/veritone.svg"
        onClick={gotoVeritone}
      />
    );
  };

  const handleBackToLogin = () => {
      history.push("/");
  };

  const initWidget = () => {
    if (!window.aiware) {
      console.error(`Please import aiware.js to mount the widget!`)
      return;
    }

    const gqlEndpoint =
      cfgByEnv.graphqlEndpoint && cfgByEnv.graphqlEndpoint.default
        ? cfgByEnv.graphqlEndpoint.default
        : cfgByEnv.api + '/v3/graphql';

    window.aiware.init(
      {
        applicationId: 'front-door-app',
        baseUrl: gqlEndpoint,
        scopedStyles: true
      },
      () => {
        window.aiware.mountWidget({
          name: window.aiware.AvailableWidgets.RESET_PASSWORD,
          elementId: WIDGET_ELEMENT_ID,
          config: {
            username,
            resetToken,
            requiresPasswordRestriction: enablePasswordRestriction,
            organizationInviteId,
            containerWidth: '100%',
            onSuccess() {
              if (redirectURI) {
                window.location = decodeURIComponent(redirectURI);
              } else {
                history.push('/')
              }
            },
          }
        })
      }
    )
  }

  useEffect(() => {
    initWidget()
  }, [])

  const loginBoxClassName = `${classes.loginBox} ${enablePasswordRestriction ? classes.loginBoxLarge : ''}`;
  return (
    <div className="App">
      <Box className={loginBoxClassName}>
        <Card className={classes.loginCard}>
          <CardHeader className={classes.cardHeader} avatar={VeritoneLogo()} />
          <div className="reset-password-widget-root" id={WIDGET_ELEMENT_ID} />
          <Box className={classes.backLogin}>
            <p className={`${classes.text} ${classes.backLoginText} ${classes.mt0}`} onClick={handleBackToLogin}>Back to Login</p>
          </Box>
        </Card>
        <p className={`${classes.text} ${classes.footerText}`}>
          &copy; {new Date().getFullYear()} Veritone, Inc. All Rights Reserved.
          <a
            className={`${classes.text} ${classes.footerText}`}
            href="https://www.veritone.com/terms"
          >
            Terms of Service
          </a>
        </p>
      </Box>
    </div>
  );
}

export default NewPassword;
