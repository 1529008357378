export const destination = [
    {
        name: 'US-AWS',
        label: 'aiWARE - US',
        description: 'Attribute, Developer, Essentials'
    },
    {
        name: 'CA-Azure',
        label: 'aiWARE - CA',
        description: 'Developer, Essentials, IDentify, Illuminate, Redact'
    },
    {
        name: 'UK-AWS',
        label: 'aiWARE - UK',
        description: 'Attribute, Developer, Essentials'
    },
    {
        name: 'SLED-AWS',
        label: 'aiWARE - US Gov 1',
        description: 'Developer, Essentials, IDentify, Illuminate, Redact'
    },
    {
        name: 'SLED-Azure',
        label: 'aiWARE - US Gov 2',
        description: 'Developer, Essentials, IDentify, Illuminate, Redact'
    },
    {
        name: 'CORE',
        label: 'Core',
        description: 'Formerly Wazee Digital Core'
    }
];