import React from "react";
import {
  Box,
  CardContent,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Divider,
  FormHelperText,
  Slide
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import CircularProgress from "@material-ui/core/CircularProgress";

import {emailValidate, requiredValidate} from "../../helper";
import * as configFile from "../../config.json";

const config = window.config || configFile.default;
const cfgByEnv = config;

const errorDefault = {
  destination: "",
  email: "",
  password: "",
};

function Login({
                 items,
                 redirectURI,
                 des = {},
                 openIdRequired,
                 openIds,
                 globalOpenIds,
                 onChangeDestination,
                 email,
                 pw,
                 onChangeEmail,
                 onChangePw,
                 onLogin,
                 onSignup,
                 classes,
                 onForgotPassword,
                 logining,
                 errorLogin,
               }) {
  const fedEnvs = ['us-fed-1', 'us-fed-2'];
  const isFed = fedEnvs.find(env => window.location.href.includes(env));
  const hidePasswordReset = isFed;
  const [error, setError] = React.useState(errorDefault);
  const emailRef = React.useRef();

  React.useEffect(() => {
    if (des.label && emailRef.current) {
      emailRef.current.focus();
    }
  }, [des, emailRef]);

  function handleChangeDestination(e) {
    setError((error) => ({
      ...error,
      destination: "",
    }));
    onChangeDestination(e);
  }

  function handleChangeEmail(e) {
    setError((error) => ({
      ...error,
      email: "",
    }));
    onChangeEmail(e);
  }

  function handleChangePassword(e) {
    setError((error) => ({
      ...error,
      password: "",
    }));
    onChangePw(e);
  }

  function handleLogin(e) {
    e.preventDefault();
    if (
      !requiredValidate(email) ||
      !emailValidate(email) ||
      !requiredValidate(pw) ||
      !des.label
    ) {
      setError({
        destination: !des.label ? "A destination is required." : "",
        email: !requiredValidate(email)
          ? "An email is required."
          : !emailValidate(email)
            ? "This is an invalid email."
            : "",
        password: !requiredValidate(pw) ? "A password is required." : "",
      });
      return;
    }
    onLogin && onLogin(e);
  }

  function handleForgotPassword(e) {
    e.preventDefault();
    if (!des.label) {
      setError({
        destination: !des.label ? "A destination is required." : "",
      });
      return;
    }
    onForgotPassword && onForgotPassword();
  }

  const onClickLoginOpenId = (url) => {
    window.location.href = url;
  };

  const renderOpenIdButtons = (openIdItems) => {
    return openIdItems.map(item => {
      const url = item.loginUrl;
      const loginButtonText = (item.loginButtonStyle && item.loginButtonStyle.btnText) ? item.loginButtonStyle.btnText : ('Login with ' + item.name)
      return (
        <Slide key={item.connectId} direction="down" in={true} timeout={1000}>
          <button
            data-testid="open-id-button"
            data-test={`open-id-button-${loginButtonText}`}
            className={classes.btnLoginOpenId}
            style={{background: (item.loginButtonStyle && item.loginButtonStyle.btnColor) ? item.loginButtonStyle.btnColor : ""}}
            onClick={() => onClickLoginOpenId(url)}
          >
            {(item.loginButtonStyle && item.loginButtonStyle.btnLogo) &&
              <img
                className={classes.openIdLogo}
                alt="icon"
                src={item.loginButtonStyle.btnLogo}/>
            }
            <p
              className={`${classes.text} ${classes.loginVerifyText}`}
              style={{color: (item.loginButtonStyle && item.loginButtonStyle.btnTextColor) ? item.loginButtonStyle.btnTextColor : ""}}
            >
              {loginButtonText}
            </p>
          </button>
        </Slide>
      )

    })
  };

  return (
    <CardContent className={classes.cardContent}>
      <p className={`${classes.text} ${classes.welcomeText}`}>Welcome back!</p>
      <p className={`${classes.text} ${classes.helperText}`}>
        Login to continue
      </p>
      <Box className={classes.form}>
        {config.showEnvironmentSelector && <FormControl
          variant="outlined"
          className={classes.formControl}
          error={!!error.destination}
        >
          <InputLabel
            id="demo-simple-select-outlined-label"
            className={classes.labelSelect}
          >
            Select Destination
          </InputLabel>
          <Select
            required
            value={des.label || ""}
            onChange={handleChangeDestination}
            label="Select Destination"
            classes={{
              root: classes.select,
            }}
            MenuProps={{
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              classes: {
                list: classes.menulist,
              },
            }}
            data-test="destination-selector"
          >
            {items.map((item, index) => (
              <MenuItem
                className={classes.menuItem}
                classes={{
                  selected: classes.menuItemSelected,
                }}
                key={item.label}
                value={item.label}
                data-test={`destination-menu-${index}`}
              >
                {item.label}
              </MenuItem>
            ))}
          </Select>
          {error.destination && (
            <FormHelperText>{error.destination}</FormHelperText>
          )}
        </FormControl>}
        <TextField
          required
          disabled={!des.label}
          className={classes.formControl}
          fullWidth
          inputRef={emailRef}
          variant="outlined"
          label="Email"
          InputLabelProps={{
            shrink: true,
          }}
          value={email}
          onChange={handleChangeEmail}
          error={!!error.email || !!errorLogin}
          helperText={error.email}
          classes={{
            root: classes.select,
          }}
          InputProps={{
            type: "email",
            classes: {
              root: classes.select,
            },
          }}
          inputProps={{
            "data-test": "email-input",
          }}
          data-test="email-field"
          data-testid="email-field"
        />
        {!openIdRequired &&
          <TextField
            className={classes.formControl}
            fullWidth
            required
            variant="outlined"
            label="Password"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              type: "password",
              classes: {
                root: classes.select,
              },
            }}
            inputProps={{
              "data-test": "password-input",
            }}
            value={pw}
            onChange={handleChangePassword}
            error={!!error.password || !!errorLogin}
            helperText={error.password}
            data-test="password-field"
            data-testid="password-field"
          />
        }
        {errorLogin && (
          <Box data-test="login-error" className={`${classes.wrongEmailText}`}>
            <InfoIcon className={classes.icon}/> {errorLogin}
          </Box>
        )}
        {!openIdRequired &&
          <>
            {!hidePasswordReset && (
              <p
                className={`${classes.text} ${classes.forgotText}`}
                onClick={handleForgotPassword}
                data-test="forgot-password">
                Forgot Password?
              </p>
            )}

            <button
              data-testid="login-button"
              data-test="login-button"
              className={classes.btnLogin}
              onClick={handleLogin}
            >
              {logining ?
                (
                  <CircularProgress
                    data-test="loading-login"
                    classes={{
                      root: classes.progressbar,
                    }}
                  />
                ) : (
                  "LOGIN"
                )}
            </button>

            {(openIds.length > 0 || globalOpenIds.length > 0)  && !openIdRequired && (
              <Box className={classes.dividerContainer}>
                <Divider classes={{root: classes.divider}}/>
                <p className={`${classes.text} ${classes.or}`}>or</p>
              </Box>
            )}
          </>
        }
      </Box>
      <div className={classes.openIdButtonContainer}>
        {renderOpenIdButtons(openIds)}
        {!openIdRequired && (renderOpenIdButtons(globalOpenIds))}
      </div>
    </CardContent>
  );
}

export default Login;
