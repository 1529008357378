import React from 'react'
import Cookies from 'universal-cookie';
import {
  Box,
  Card,
  CardHeader,
} from '@material-ui/core';
import { useLocation, useHistory } from 'react-router-dom';
import Forgot from '../components/ForgotComponent/Forgot';
import ResetPassword from '../components/ForgotComponent/ResetPassword'
import useStyles from '../components/LoginComponent/styles';
import * as configFile from '../config.json';
import { destination } from '../constant';
import '../App.css';

const config =  window.config || configFile.default;
const resetPwRoute = '/v1/admin/password/request-reset'
const cookies = new Cookies();

const cfgByEnv = config;

const items = destination.map(item => ({
  ...item,
  link: cfgByEnv['api'][item.name],
  switchApp: cfgByEnv['switchApp'][item.name],
}));

let instanceApp;
// temporarily disable instance app to prevent cookie change issues
// instanceApp = cookies.get("instanceApp");

function ForgotPage() {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [des, setDes] = React.useState(instanceApp);
  const [status, setStatus] = React.useState('forgot');
  const [email, setEmail] = React.useState();
  const [forgetting, setForgetting] = React.useState(false);
  React.useEffect(() => {
    const { destination = {}, email } = location.state || {};
    destination.link ? setDes(destination) : setDes(items[0]);
    email && setEmail(email)
  }, [location])
  const handleChangeStatus = (value) => () => {
    setStatus(value);
  };
  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleForgotPw = (e) => {
    e.preventDefault();
    setForgetting(true);
    !forgetting && fetch(des.link + resetPwRoute, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: JSON.stringify({
        userName: email
      })
    })
      .then(function (response) {
        if (response.status === 204) {
          setStatus('resetPw')
        }
        setForgetting(false);
      })
      .catch(function (error) {
        console.log(error);
        setForgetting(false);
      });
  };

  const gotoVeritone = () => {
    window.location = 'https://www.veritone.com/';
  }
  const VeritoneLogo = () => {
    return <img alt="logo" className={classes.logo} src="../images/veritone.svg" onClick={gotoVeritone} />
  }

  const handleBackToLogin = () => {
    history.push("/");
  }

  return (
    <div className="App">
      <Box className={classes.loginBox}>
        <Card className={classes.loginCard}>
          <CardHeader className={classes.cardHeader} avatar={VeritoneLogo()} />
          {status === 'forgot' && <Forgot
            email={email}
            onChangeEmail={handleChangeEmail}
            onChangeStatus={handleChangeStatus}
            onForgotPw={handleForgotPw}
            classes={classes}
            onBackToLogin={handleBackToLogin}
            forgetting={forgetting}
          />}
          {status === 'resetPw' && <ResetPassword
            onBackToLogin={handleBackToLogin}
            classes={classes}
            onChangeStatus={handleChangeStatus}
          />}
        </Card>
        <p className={`${classes.text} ${classes.footerText}`}>
          &copy; {new Date().getFullYear()} Veritone, Inc. All Rights Reserved.      </p>
      </Box>
    </div>
  );
}

export default ForgotPage;
