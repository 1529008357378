const requiredValidate = (value) => {
    return value && value.length > 0;
}

const emailValidate = (value) => {
    return /^(([^<>()\\[\]\\.,;:\s@\\"]+(\.[^<>()\\[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\]\\.,;:\s@\\"]+\.)+[^<>()[\]\\.,;:\s@\\"]{2,})$/.test(value);
}

export {
    requiredValidate,
    emailValidate
}
