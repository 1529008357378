import React from 'react';
import {
  Box,
  CardContent,
} from '@material-ui/core';

function ResetPassword({
  onChangeStatus,
  classes,
  onBackToLogin
}) {

  return (
    <CardContent className={classes.cardContent}>
      <p className={`${classes.text} ${classes.welcomeText}`}>Password reset requested</p>
      <p className={`${classes.text} ${classes.helperText} ${classes.resetPwHelperText}`}>
        Your password reset will be sent to the email provided if it matches our records.
      </p>
      <Box className={classes.form} >
        <button
          data-test="request-again-button"
          className={classes.btnLogin}
          onClick={onChangeStatus("forgot")}
        >
          REQUEST PASSWORD AGAIN
        </button>
      </Box>
      <Box className={classes.backLogin}>
        <p className={`${classes.text} ${classes.backLoginText}`} onClick={onBackToLogin}>Back to Login</p>
      </Box>
    </CardContent>
  );
}

export default ResetPassword;
