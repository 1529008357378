import React from "react";
import Cookies from "universal-cookie";
import { Box, Card, CardHeader } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Login from "../components/LoginComponent/Login";
import useStyles from "../components/LoginComponent/styles";

import { destination } from "../constant";
import * as configFile from "../config.json";
import "../App.css";
import { emailValidate, requiredValidate } from "../helper";

const config = window.config || configFile.default;
const loginRoute = "/v1/admin/login";
const openIdEndpoint = "/api/admin/openid/org/";
const globalOpenIdEndpoint = "/api/admin/openid";
const cookies = new Cookies();

const cfgByEnv = config;
const items = destination.map((item) => ({
  ...item,
  test: "1234",
  link: cfgByEnv["api"][item.name],
  switchApp: cfgByEnv["switchApp"][item.name],
}));

function debounce(func, wait) {
  let timeout;
  return function (...args) {
    const context = this;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      func.apply(context, args);
    }, wait);
  };
}

let instanceApp;
// temporarily disable instance app to prevent cookie change issues for vtn users
// instanceApp = cookies.get("instanceApp");
cookies.remove("instanceApp");

function LoginPage() {
  const classes = useStyles();
  const history = useHistory();
  const [openIdRequired, setOpenIdRequired] = React.useState(false);
  const [openIds, setOpenIds] = React.useState([]);
  const [globalOpenIds, setGlobalOpenIds] = React.useState([]);
  const [login, setLogin] = React.useState(false);
  const [des, setDes] = React.useState(instanceApp);
  const [email, setEmail] = React.useState();
  const [pw, setPw] = React.useState();
  const [error, setLoginErr] = React.useState("");
  const [redirectURI, setRedirectURI] = React.useState(null);
  const [hasDetectDes, setHasDetectDes] = React.useState(false);
  const debounceOnChange = React.useCallback(debounce(checkOpenId, 300), []);

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setRedirectURI(urlParams.get('redirect'));

    // if aiWARE Anywhere set the destination by default
    if (cfgByEnv.isAnywhere || !cfgByEnv.showEnvironmentSelector) {
      setDes({
        value: "aiware-anywhere",
        name: "default",
        link: cfgByEnv.api.default,
        label: "aiware Anywhere",
        switchApp: cfgByEnv.switchApp.default,
      });
    }

    if (!cfgByEnv.isAnywhere && !des) {
      setDes({
        name: "default",
        link: cfgByEnv.api.default,
        label: "aiWARE - US",
        switchApp: cfgByEnv.switchApp.default,
      });
    }

  }, []);

  React.useEffect(() => {
    if (des && des.name) {
      fetch(`${cfgByEnv.api[des.name] + globalOpenIdEndpoint}`)
        .then((response) => {
          if (response.status === 200) {
            response.json().then((data) => {
              setGlobalOpenIds(data.results);
            });
          } else {
            if (window.DEBUG) {
              console.error(
                "was not able to retrieve global open id providers",
                response
              );
            }
          }
        })
        .catch(function (error) {
          if (window.DEBUG) {
            console.error(
              "was not able to retrieve global open id providers",
              error
            );
          }
        });
    }
  }, [des]);

  const getDomainURI = (uri = "") => {
    const [result = ""] =
    uri.match(/^(?:https?:)?(?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?]+)/g) ||
    [];
    return result;
  };

  React.useEffect(() => {
    if (redirectURI) {
      const value = items.find(
        (it) => getDomainURI(it.link) === getDomainURI(redirectURI)
      );

      if (value && value.label !== "Core") {
        setHasDetectDes(true);
        cookies.set("instanceApp", value);
        setDes(value);
      }
    }
  }, [redirectURI]);

  const handleChangeDestination = (e) => {
    const value = items.find((it) => it.label === e.target.value);
    cookies.set("instanceApp", value);
    const { label = "" } = des || {};

    if (hasDetectDes && value.label !== label) {
      history.push("/");
    }

    if (e.target.value === "Core") {
      window.location = value.link;
    } else {
      setDes(value);
      setLoginErr("");
    }
  };

  const openIdHelper = (openIds) => {
    if (openIds.length) {
      const requiredIds = openIds.filter((item) => item.required);
      const nonGlobalIds = openIds.filter((item) => !item.isGlobal);
      if (requiredIds.length) {
        setOpenIdRequired(true);
        setOpenIds(requiredIds);
      } else {
        setOpenIds(nonGlobalIds);
      }
    }
  };

  function handleSuccessfulLogin(isOpenId, response) {
    if (isOpenId) {
      response.json().then((data) => {
        openIdHelper(data.results);
      });
    } else if (redirectURI) {
      window.location = redirectURI;
    } else {
      window.location = des.switchApp;
    }
  }

  function handleLoginNotFound(isOpenId) {
    if (!isOpenId) {
      setLoginErr("Email or Password is incorrect.");
    }
    // else do nothing - this would be user not found
  }

  function handleLoginUnauthorized (response) {
    response.json().then((data) => {
      if (data.errorCode === "activeConcurrentUserSession") {
        setLoginErr("There is an active session for this user. Close all active sessions and log in again.");
      } else {
        setLoginErr("Something went wrong with login. Please try again.");
      }
    });
  }

  function checkLoginResponse(isOpenId) {
    return function (response) {
      switch(response?.status) {
        case 200: {
          handleSuccessfulLogin(isOpenId, response);
          break;
        }
        case 404: {
          handleLoginNotFound(isOpenId);
          break;
        }
        case 403: {
          handleLoginUnauthorized(response);
          break;
        }
        default: {
          setLoginErr("Something went wrong with login. Please try again.");
          if (isOpenId && window.DEBUG) {
            console.error(
              "was not able to retrieve global open id providers",
              response
            );
          }
        }
      }
      if (!isOpenId) {
        setLogin(false);
      }
    }
  }
  function checkOpenId(email, des) {
    // reset any time changing
    setOpenIds([]);
    setOpenIdRequired(false);
    if (!requiredValidate(email) || !emailValidate(email)) {
      return;
    }
    fetch(`${cfgByEnv.api[des.name] + openIdEndpoint + email}`)
      .then(checkLoginResponse(true))
      .catch(function (error) {
        setLoginErr("Something went wrong with login. Please try again.");
        if (window.DEBUG) {
          console.error(
            "was not able to retrieve global open id providers",
            error
          );
        }
      });
  }

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
    setLoginErr("");
    debounceOnChange(e.target.value, des);
  };

  const handleChangePw = (e) => {
    setPw(e.target.value);
    setLoginErr("");
  };

  const handleLogin = (e) => {
    e.preventDefault();
    setLogin(true);
    !login &&
    fetch(des.link + loginRoute, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({
        userName: email,
        password: pw,
      }),
    })
      .then(checkLoginResponse(false))
      .catch(function (error) {
        setLoginErr("Something went wrong with login. Please try again.");
        setLogin(false);
      });
  };

  React.useEffect(() => {
    sessionStorage.setItem("des", JSON.stringify(des));
    sessionStorage.setItem("redirectURI", JSON.stringify(redirectURI));
  }, [des, redirectURI]);

  const handleSignup = React.useCallback(() => {
    if (redirectURI) {
      const appKey = ["automate", "benchmark", "developer", "redact"].find(
        (item) => redirectURI.includes(item)
      );
      if (appKey) {
        window.location = cfgByEnv.signupPageUris[appKey];
        return;
      }
    }
    window.location = cfgByEnv.signupPageUris.default;
  }, [redirectURI]);

  const gotoVeritone = () => {
    window.location = "https://www.veritone.com/";
  };
  const VeritoneLogo = () => {
    return (
      <img
        alt="logo"
        className={classes.logo}
        src="../images/veritone.svg"
        onClick={gotoVeritone}
      />
    );
  };

  const handleForgotPassword = () => {
    history.push("/forgot", {
      destination: des,
      email: email,
    });
  };

  return (
    <div className="App">
      <Box className={classes.loginBox}>
        <Card className={classes.loginCard}>
          <CardHeader className={classes.cardHeader} avatar={VeritoneLogo()} />
          <Login
            openIdRequired={openIdRequired}
            openIds={openIds}
            globalOpenIds={globalOpenIds}
            items={items}
            des={des}
            redirectURI={redirectURI}
            onChangeDestination={handleChangeDestination}
            email={email}
            pw={pw}
            onChangeEmail={handleChangeEmail}
            onChangePw={handleChangePw}
            onLogin={handleLogin}
            onSignup={handleSignup}
            classes={classes}
            onForgotPassword={handleForgotPassword}
            errorLogin={error}
            logining={login}
          />
        </Card>
        <p className={`${classes.text} ${classes.footerText}`}>
          &copy; {new Date().getFullYear()} Veritone, Inc. All Rights Reserved.
          <a
            className={`${classes.text} ${classes.footerText}`}
            href="https://www.veritone.com/terms"
          >
            Terms of Service
          </a>
        </p>
      </Box>
    </div>
  );
}

export default LoginPage;
