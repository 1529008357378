import React from "react";
import { Box, CardContent } from "@material-ui/core";

const NoLink = ({ classes, onBackToLogin, onGoToLinking }) => {
    return (
        <CardContent className={classes.cardContent}>
            <p className={`${classes.text} ${classes.welcomeText}`} data-test="header">
                Account link does not exist!
            </p>
            <Box mt={3} />
            <p className={`${classes.text} ${classes.subText}`}>
                This AiWARE account does not exist. Are you sure you’re using the
                correct Authentication Provider?
            </p>
            <Box mt={6} />
            <button
                data-test="go-to-link"
                className={classes.btnLogin}
                onClick={onGoToLinking}
            >
                Link to Existing Account
            </button>
            <Box className={classes.backLogin}>
                <p
                    className={`${classes.text} ${classes.backLoginText}`}
                    onClick={onBackToLogin}
                    data-test="back-to-login"
                >
                    Back to Login
                </p>
            </Box>
        </CardContent>
    );
};

export default NoLink;
